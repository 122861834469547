.header {
  height: 100px;
}

.logo {
  width: auto;
  height: 100%
}

/* Medium devices (tablets, 768px and up) */
@media (min-width:  768px) {
  .header {
    height: 120px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width:  992px) {
  .header {
    height: 150px;
  }
}