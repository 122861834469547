.container {
    background-color: var(--dark-grey-color);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.pdf {
    width: 10px;
    color: transparent;
}

.pdf:hover {
    color: transparent;
}