.container {
    margin: 0;
}

.container > button {
    background-color: transparent;
    border: none;
    color: inherit;
    transition: color 0.3s ease-in-out;
}

.container > button:hover {
    color: var(--dark-color);
}

.activate {
    color: var(--dark-color) !important;
    font-weight: bold;
}
