@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto&display=swap');

:root {
    --font-roboto: 'Roboto', sans-serif;
    --font-anton: 'Anton', sans-serif;
    --grey-color: #b7b7b7;
    --grey-light-color: #d8d8d8;
    --dark-color: #000000;
    --light-color: #ffffff;
    --yellow-color: #ffd506;
    --dark-grey-color: #333333;
}

body {
    font-size: 14px;
    color: var(--grey-color);
    font-family: var(--font-roboto);
    text-rendering: optimizeLegibility;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    body {
        font-size: 16px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    body {
        font-size: 18px;
    }
}
