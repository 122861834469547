.container {
    color: var(--grey-color);
    margin: 1rem 0;
}

.container a {
    color: inherit;
    margin: 0 0.5rem;
    transition: color 0.5s ease-in-out;
}

.container a:hover {
    color: var(--light-color);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container a {
        font-size: 1.2em;
        margin: 0 1rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .container a {
        font-size: 1.5em;
    }
}
