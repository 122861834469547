.container {
    height: 6rem;
}

.white {
    background-color: var(--light-color);
}

.black {
    background-color: var(--dark-color);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        height: 8rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .container {
        height: 10rem;
    }
}
