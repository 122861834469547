.container {
    background-color: var(--dark-color);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        min-height: 45rem;
    }
}

.container h2 {
    font-size: 3em;
}

.yellowTitle {
    color: var(--yellow-color) !important;
}

.container h2,
.container h4,
.container h6 {
    text-transform: uppercase;
    margin-bottom: 1.2rem;
    letter-spacing: 0.2rem;
}

.container h2,
.container h4 {
    font-family: var(--font-anton);
    color: var(--grey-light-color);
}

.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 15rem;
}

.welcome {
    background-image: url('../../images/welcome.png');
}

.urban-trash {
    background-image: url('../../images/urban-trash.png');
}

.martorana-chestnuts {
    background-image: url('../../images/martorana-chestnuts.png');
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .background {
        height: 20rem;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .container {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .container p {
        font-size: 0.85em;
    }

    .background {
        height: 100%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .container {
        padding: 2rem;
    }

    .container p {
        font-size: 1em;
    }
}
