@import url(https://fonts.googleapis.com/css2?family=Anton&family=Roboto&display=swap);
.LanguageSelector_container__3malb {
    margin: 0;
}

.LanguageSelector_container__3malb > button {
    background-color: transparent;
    border: none;
    color: inherit;
    transition: color 0.3s ease-in-out;
}

.LanguageSelector_container__3malb > button:hover {
    color: var(--dark-color);
}

.LanguageSelector_activate__11Cz4 {
    color: var(--dark-color) !important;
    font-weight: bold;
}

.Header_header__1VCKf {
  height: 100px;
}

.Header_logo__3_SJs {
  width: auto;
  height: 100%
}

/* Medium devices (tablets, 768px and up) */
@media (min-width:  768px) {
  .Header_header__1VCKf {
    height: 120px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width:  992px) {
  .Header_header__1VCKf {
    height: 150px;
  }
}
.Divider_container__2wzz6 {
    height: 6rem;
}

.Divider_white__1iGRh {
    background-color: var(--light-color);
}

.Divider_black__1v7S7 {
    background-color: var(--dark-color);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .Divider_container__2wzz6 {
        height: 8rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .Divider_container__2wzz6 {
        height: 10rem;
    }
}

.Button_button__1RwfN {
    background-color: transparent;
    border: 2px solid var(--grey-color);
    color: var(--grey-color);
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    margin: 2rem 0;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.Button_button__1RwfN:hover {
    background-color: var(--grey-color);
    color: var(--light-color);
    text-decoration: none;
}

.SocialButton_container__1Z6oe {
    color: var(--grey-color);
    margin: 1rem 0;
}

.SocialButton_container__1Z6oe a {
    color: inherit;
    margin: 0 0.5rem;
    transition: color 0.5s ease-in-out;
}

.SocialButton_container__1Z6oe a:hover {
    color: var(--light-color);
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .SocialButton_container__1Z6oe a {
        font-size: 1.2em;
        margin: 0 1rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .SocialButton_container__1Z6oe a {
        font-size: 1.5em;
    }
}

.ProjectCard_container__-7dPS {
    background-color: var(--dark-color);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .ProjectCard_container__-7dPS {
        min-height: 45rem;
    }
}

.ProjectCard_container__-7dPS h2 {
    font-size: 3em;
}

.ProjectCard_yellowTitle__1Uw_z {
    color: var(--yellow-color) !important;
}

.ProjectCard_container__-7dPS h2,
.ProjectCard_container__-7dPS h4,
.ProjectCard_container__-7dPS h6 {
    text-transform: uppercase;
    margin-bottom: 1.2rem;
    letter-spacing: 0.2rem;
}

.ProjectCard_container__-7dPS h2,
.ProjectCard_container__-7dPS h4 {
    font-family: var(--font-anton);
    color: var(--grey-light-color);
}

.ProjectCard_background__Ecg4M {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 15rem;
}

.ProjectCard_welcome__2uhXN {
    background-image: url(/static/media/welcome.3053e7cf.png);
}

.ProjectCard_urban-trash__1eJf9 {
    background-image: url(/static/media/urban-trash.344f83f1.png);
}

.ProjectCard_martorana-chestnuts__24LBV {
    background-image: url(/static/media/martorana-chestnuts.9e417d78.png);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .ProjectCard_background__Ecg4M {
        height: 20rem;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .ProjectCard_container__-7dPS {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .ProjectCard_container__-7dPS p {
        font-size: 0.85em;
    }

    .ProjectCard_background__Ecg4M {
        height: 100%;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .ProjectCard_container__-7dPS {
        padding: 2rem;
    }

    .ProjectCard_container__-7dPS p {
        font-size: 1em;
    }
}

.About_container__vm32Q {
    color: var(--dark-color);
    text-align: center;
    padding: 2rem;
}

.About_container__vm32Q h2,
.About_container__vm32Q h4 {
    font-weight: 700;
    margin-bottom: 2rem;
}

.About_container__vm32Q h2 {
    font-family: var(--font-anton);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .About_container__vm32Q {
        background-image: url(/static/media/chiesa-sanna.a3cbd086.png);
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
    }
}

.Footer_container__st7Zp {
    background-color: var(--dark-grey-color);
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Footer_pdf__1jt-j {
    width: 10px;
    color: transparent;
}

.Footer_pdf__1jt-j:hover {
    color: transparent;
}
:root {
    --font-roboto: 'Roboto', sans-serif;
    --font-anton: 'Anton', sans-serif;
    --grey-color: #b7b7b7;
    --grey-light-color: #d8d8d8;
    --dark-color: #000000;
    --light-color: #ffffff;
    --yellow-color: #ffd506;
    --dark-grey-color: #333333;
}

body {
    font-size: 14px;
    color: var(--grey-color);
    font-family: var(--font-roboto);
    text-rendering: optimizeLegibility;
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    body {
        font-size: 16px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    body {
        font-size: 18px;
    }
}

