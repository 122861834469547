.button {
    background-color: transparent;
    border: 2px solid var(--grey-color);
    color: var(--grey-color);
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    margin: 2rem 0;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.button:hover {
    background-color: var(--grey-color);
    color: var(--light-color);
    text-decoration: none;
}
