.container {
    color: var(--dark-color);
    text-align: center;
    padding: 2rem;
}

.container h2,
.container h4 {
    font-weight: 700;
    margin-bottom: 2rem;
}

.container h2 {
    font-family: var(--font-anton);
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container {
        background-image: url('../../images/chiesa-sanna.png');
        background-size: contain;
        background-position: right;
        background-repeat: no-repeat;
    }
}
